<template>
  <Page class="page-not-found">
    <Title title="Not Found page" />
    <div class="link">
      <router-link :to="paths().home">Back to home</router-link>
    </div>
  </Page>
</template>

<script>
  import Title from "../components/common/Title";
  import Page from "../components/layouts/LayoutPage";
  import { mixinPaths } from "../mixins/mixinPaths";

  export default {
    name: 'Register',
    components: { Title, Page },
    mixins: [
      mixinPaths,
    ],
  }
</script>

<style lang="scss" scoped>
  .page-not-found {
    .link {
      text-align: center;

      a {
        font-size: 2rem;
        color: $c-brand-primary;
      }
    }
  }
</style>
